import dayjs from 'dayjs'
import { NOT_FOUND } from './machinery/statusCodes'
import { asRouteMap } from '@kaliber/routing'
import { cookieConfiguration } from '/machinery/tracking/cookieConfiguration'
import { image } from './placeholder-data/placeholder-helpers'
import { extractArticles } from '/features/regionArticles/extractArticles'

const routeData = getRouteData()

export const routeMap = asRouteMap(
  {
    root: '',
    api: {
      path: 'api',

      v1: {
        path: 'v1',

        woodwing: {
          path: 'woodwing',

          publish: {
            path: 'publish',
            data: requestHandlers => requestHandlers.woodwingPublish,
          },
        },

        postcode: {
          path: 'postcode',
          data: requestHandlers => requestHandlers.resolveLinkForPostcode
        },

        cookieConfiguration: {
          path: 'consents',
          data: requestHandlers => requestHandlers.setCookieConfiguration,
        },

        search: {
          path: 'search',
          data: requestHandlers => requestHandlers.search,
        },

        searchWithExcludedRegions: {
          path: 'searchWithExcludedRegions',
          data: requestHandlers => requestHandlers.searchWithExcludedRegions
        },

        getTags: {
          path: 'getTags',
          data: requestHandlers => requestHandlers.getTags
        }
      },

      sitemap: {
        path: 'sitemap',
        data: requestHandlers => requestHandlers.sitemap,
      },

      notFound: '*'
    },
    app: {
      path: ':language',
      data: routeData.app.data,

      home: {
        path: '',
        data: routeData.app.home
      },

      articles: {
        path: 'articles',

        single: {
          path: ':idOrIds',
          data: routeData.app.articleDetailCanonical
        }
      },

      issue: {
        path: ':issue',
        data: routeData.app.issue,
        region: {
          path: ':bankcode',
          data: routeData.app.articleOverview,
          article: {
            path: ':rubricSlug',
            data: routeData.app.articleDetail
          },
        }

      },

      redactie: {
        path: 'redactie',
        data: routeData.app.redactie
      },

      cookiePolicy: {
        path: 'cookie-beleid',
        data: routeData.app.cookiePolicy
      },

      notFound: {
        path: '*',
        data: routeData.app.notFound
      },
    }
  },
  { trailingSlash: true }
)

function getRouteData() {
  return {
    app: {
      data: {
        fetch: {
          cookieConfiguration: requestHandlers => requestHandlers.getCookieConfiguration,
          platformReleaseDate: requestHandlers => requestHandlers.getPlatformReleaseData
        },
        derived: ({ data, params }) => ({
          dataLayer: {
            ...resolveCookieSettingsForDataLayer({ data }),
            metadata: {
              platform: {
                releasedate: data.platformReleaseDate
              },
              content: {
                language: params.language,
              }
            }
          }
        }),
      },

      home: {
        fetch: {
          doc: requestHandlers => requestHandlers.getHomeData,
          issues: requestHandlers => requestHandlers.getIssuesForHome,
        },
        derived: ({ derived, data }) => deriveIssueData('frontpage', { derived, data })
      },

      issue: {
        fetch: {
          doc: requestHandlers => requestHandlers.getIssueData,
          issues: requestHandlers => requestHandlers.getIssuesForIssuePage,
        },
        derived: ({ derived, data }) => deriveIssueData('region-picker', { derived, data })
      },

      articleOverview: {
        fetch: {
          regionData: requestHandlers => requestHandlers.getRegionData,
          issues: requestHandlers => requestHandlers.getIssuesWithinRegion,
        },
        derived: ({ data, derived, params }) => deriveArticleOverviewData({ data, derived, params })
      },

      articleDetail: {
        fetch: {
          doc: requestHandlers => requestHandlers.getPageData,
          issues: requestHandlers => requestHandlers.getIssuesWithinRegion,
        },
        derived: ({ data, derived, params }) => {
          const id = data.doc?.id

          return {
            canonical: id ? routeMap.app.articles.single({ language: params.language, idOrIds: id }) : null,
            doc: addSeoDataToArticle(data.doc),
            dataLayer: getDataLayerForArticleDetail({ derived, data })
          }
        }
      },

      articleDetailCanonical: {
        fetch: {
          doc: requestHandlers => requestHandlers.getArticleData,
        },
        derived: ({ data, derived, params }) => {
          if (data?.doc?.status === NOT_FOUND)
            return { status: NOT_FOUND, ...deriveNotFoundData({ data, derived }) }

          const id = data.doc?.id

          return {
            canonical: id ? routeMap.app.articles.single({ language: params.language, idOrIds: id }) : null,
            doc: addSeoDataToArticle(data.doc),
            dataLayer: getDataLayerForArticleDetail({ derived, data })
          }
        }
      }
    },

    cookiePolicy: {
      derived: ({ derived, data }) => deriveCookiePolicyData({ derived, data }),
    },

    redactie: {
      derived: ({ derived, data }) => deriveRedactieData({ derived, data })
    },

    notFound: {
      derived: ({ derived, data }) => deriveNotFoundData({ derived, data })
    }
  }
}

function deriveIssueData(type, { derived, data }) {
  if (data?.doc?.status === NOT_FOUND)
    return { status: NOT_FOUND, ...deriveNotFoundData({ data, derived }) }

  return {
    ...derived,
    doc: {
      ...data.doc,
      seo: {
        title: 'Kies jouw regio',
        description: 'Rabo &Co is ons ledenmagazine. In woord en beeld staan we stil bij bijzondere mensen en initiatieven in jouw regio.',
        social: {
          shareImage: defaultShareImage()
        }
      }
    },
    dataLayer: {
      ...derived.dataLayer,
      metadata: {
        ...derived.dataLayer.metadata,
        template: {
          type
        }
      }
    }
  }
}

function deriveArticleOverviewData({ data, derived, params }) {
  return {
    doc: {
      ...data.doc,
      seo: {
        title: data.regionData?.region,
        description: `Bekijk alle artikelen in de regio ${data.regionData?.region}`,
        social: {
          shareImage: getShareImageForDeckfeed({ data })
        }
      }
    },
    dataLayer: {
      ...derived.dataLayer,
      metadata: {
        ...derived.dataLayer?.metadata,
        rabo: {
          bankcode: data.regionData?.bank.bankcode,
          bankname: toLowerCase(data.regionData?.bank.naam),
          edition: params.issue,
          kring: data.regionData?.bank.kringnummer,
        },
        template: {
          type: 'article-overview'
        }
      }
    }
  }
}

function deriveRedactieData({ derived, data }) {
  return {
    ...derived,
    doc: {
      ...derived.doc,
      ...data.doc,
      seo: {
        title: 'Redactie',
        social: defaultSocialShareImage(),
      }
    },
    dataLayer: {
      ...derived.dataLayer,
      metadata: {
        ...derived.dataLayer.metadata,
        template: {
          type: 'redactie'
        }
      }
    }
  }
}

function deriveCookiePolicyData({ derived, data }) {
  return {
    ...derived,
    doc: {
      ...data.doc,
      seo: {
        title: 'Privacy en cookies',
        social: defaultSocialShareImage(),
      }
    },
    dataLayer: {
      ...derived.dataLayer,
      metadata: {
        ...derived.dataLayer.metadata,
        template: {
          type: 'cookie-beleid'
        }
      }
    }
  }
}

function deriveNotFoundData({ derived, data }) {
  return {
    ...derived,
    doc: {
      ...data.doc,
      seo: {
        title: 'Pagina niet gevonden',
        social: defaultSocialShareImage(),
      }
    },
    dataLayer: {
      ...derived.dataLayer,
      metadata: {
        ...derived.dataLayer.metadata,
        template: {
          type: '404'
        }
      }
    }
  }
}

function getDataLayerForArticleDetail({ derived, data }) {
  return {
    ...derived.dataLayer,
    metadata: {
      ...derived.dataLayer?.metadata,
      content: {
        ...derived.dataLayer?.metadata?.content,
        id: data.doc?.id,
        author: data.doc?.content.metadata.credits?.map(x => x.identifier === 'author' ? x.text : null).filter(Boolean) ?? undefined,
        datecreated: formatDate(data.doc?.dataLayer?.datecreated) || undefined,
        dateupdated: formatDate(data.doc?.dataLayer?.dateupdated) || undefined,
        language: 'nl',
        region: toLowerCase(data.regionData?.bank.regio),
        tag: data.doc?.metadata.tags || undefined,
        title: toLowerCase(data.doc?.content?.hero?.title),
        type: data.doc?.metadata.template,
        readtime: data.doc?.metadata.readTime || 0,
        wordcount: data.doc?.dataLayer?.wordcount || 0,
        charscount: data.doc?.dataLayer?.charscount || 0,
        parascount: data.doc?.dataLayer?.parascount || 0,
        feedindex: data.regionData?.feedItems && data.doc ? determineFeedIndex(data.regionData?.feedItems) : undefined
      },
      template: {
        ...derived.dataLayer?.metadata?.template,
        type: 'article',
      },
      rabo: {
        ...derived.dataLayer?.metadata.rabo,
        rubric: toLowerCase(data.doc?.metadata.rubric.name),
        edition: derived.doc?.issue
      }
    }
  }

  function determineFeedIndex(feedItems) {
    const articles = extractArticles(feedItems)

    return [
      articles.findIndex((x) => x.id === data.doc.id) + 1,
      extractArticles(feedItems).length
    ].join('_')
  }

  function formatDate(string) {
    return string ? dayjs(string).format('YYYY-MM-DD') : null
  }
}

function toLowerCase(string) {
  return string && string.toLowerCase()
}

function resolveCookieSettingsForDataLayer({ data }) {
  return {
    ...cookieConfiguration(data?.cookieConfiguration?.configuration ?? null).cookieConfiguration,
    // TODO use classifications RABOCO-308
    personalization_storage: 'denied',
    security_storage: 'granted',
  }
}

function getShareImageForDeckfeed({ data }) {
  const [firstItem] = data.regionData?.feedItems || []

  if (!firstItem)
    return defaultShareImage()

  const shareImage = firstItem.metadata.template === 'hero'
    ? firstItem.content.hero.image
    : defaultShareImage()

  return shareImage
}

function defaultSocialShareImage() {
  return {
    shareImage: defaultShareImage()
  }
}

function defaultShareImage() {
  return image({ src: 'image-clp6tyg4b0000dnb66khf65z4-1200x675-webp' })
}

function addSeoDataToArticle(doc) {
  if (!doc)
    return null

  return {
    ...doc,
    seo: {
      ...doc?.metadata?.seo,
      title: doc?.metadata?.seo?.title || doc?.content?.hero?.title,
      social: {
        shareImage: doc?.content?.hero?.image || defaultShareImage()
      }
    }
  }
}
